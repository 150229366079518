const path = require('path');

module.exports = {
  siteTitle: 'William-Jack Dalessandro (Billy-Jack)',
  siteTitleShort: 'BJD',
  siteDescription: 'William-Jack/Billy-Jack Dalessandros personal website',
  siteUrl: 'https://billy-jack.com/',
  themeColor: '#000',
  backgroundColor: '#fff',
  pathPrefix: null,
  logo: path.resolve(__dirname, 'src/images/toiletcomputer.jpg'),
  social: {
    twitter: 'gatsbyjs',
    fbAppId: '966242223397117',
  },
};
